import React, { useState, useRef, useEffect } from 'react';
import useOnScreen from '../../../../components/useOnScreen';
import CouponInput from '../../GeneralComponents/CouponInput';
import TicketInfoMinimalistic from './TicketInfoMinimalistic';
import { categorizeTickets, checkTeamBasedRegStatus } from '../../../../helpers/common';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import _ from 'lodash';

const TeamBasedComponent = dynamic(() => import('../../GeneralComponents/TeamBased/TeamBasedComponent'));

const EventPricingMinimalistic = ({
  eventUrl,
  newQuery,
  setCurrSection,
  samplePage,
  getTicketDataFunction,
  hasCoupons,
  hasAccessCodes,
  ticketData,
  setTicketData,
  eventData,
  isLoading,
  groupDiscounts,
}) => {
  const router = useRouter();
  const [couponCode, setCouponCode] = useState('');
  const [uncategorized, setUncategorized] = useState([]);
  const [categorized, setCategorized] = useState([]);
  const [teamRegStatus, setTeamRegStatus] = useState(0); // 0 -> No team-based tickets, 1 -> Only team-based tickets, 2 -> Team-based and individual tickets
  const [isAccessCode, setIsAccessCode] = useState(false);

  const isReferral =
    !_.isEmpty(newQuery) &&
    newQuery.referred_by !== '' &&
    newQuery.referral_mode !== '' &&
    newQuery.campaign_id !== '';

  useEffect(() => {
    const { uncategorized, categorized } = categorizeTickets(ticketData);
    const teamRegistrationStatus = checkTeamBasedRegStatus(ticketData);
    setTeamRegStatus(teamRegistrationStatus);
    setUncategorized(uncategorized);
    setCategorized(categorized);
  }, [ticketData]);

  const ref = useRef(null);

  const getCouponDetails = (couponDetails = false, coupon, accessCodeTicket = false) => {
    let newTicketDetails = [];
    accessCodeTicket.length > 0
      ? (newTicketDetails = [...accessCodeTicket])
      : (newTicketDetails = [...ticketData]);
    const couponTickets = couponDetails.applicable_tickets;
    newTicketDetails.forEach((ticket) => {
      const ticketPrice = ticket.ticket_price;
      ticket.final_price = ticketPrice;
      ticket.discount_remaining = null;
      ticket.coupon_applicable = null;
      ticket.discounted_price = null;

      let finalPrice;
      // If coupon code and not access code
      if (couponDetails && !accessCodeTicket) {
        ticket.discount = null;
        if (couponTickets.includes(ticket.ticket_id)) {
          ticket.coupon_applicable = true;
          ticket.discount = couponDetails.discount;
          if (couponDetails.discount_type === 1) {
            finalPrice = ticketPrice - ticket.discount;
            ticket.discounted_price = ticket.discount;
            ticket.final_price =
              finalPrice.toFixed(2) % 1 !== 0 ? finalPrice.toFixed(2) : finalPrice;
          } else if (couponDetails.discount_type === 2) {
            finalPrice = ticketPrice - (ticket.discount / 100) * ticketPrice;
            ticket.discounted_price = (ticket.discount / 100) * ticketPrice;
            ticket.final_price =
              finalPrice.toFixed(2) % 1 !== 0 ? finalPrice.toFixed(2) : finalPrice;
          }
          if (finalPrice <= 0) {
            ticket.final_price = 0;
          }
        }
      } else {
        // If access code
        ticket.coupon_applicable = true;
        if (teamRegStatus > 0) {
          // If access code is coming from a team-based event
          ticket.teamBasedEvent = true;
        }
        if (ticket.discount_type === 1) {
          finalPrice = ticketPrice - ticket.discount;
          ticket.discounted_price = ticket.discount;
          ticket.final_price =
            finalPrice.toFixed(2) % 1 !== 0 ? finalPrice.toFixed(2) : finalPrice;
        } else if (ticket.discount_type === 2) {
          finalPrice = ticketPrice - (ticket.discount / 100) * ticketPrice;
          ticket.discounted_price = (ticket.discount / 100) * ticketPrice;
          ticket.final_price =
            finalPrice.toFixed(2) % 1 !== 0 ? finalPrice.toFixed(2) : finalPrice;
        }
        if (finalPrice <= 0) {
          ticket.final_price = 0;
        }
      }
    });
    setCouponCode(coupon);
    setTicketData(newTicketDetails);
  };

  const removeCoupon = (isAccessCode = false) => {
    if (!isAccessCode) {
      let newTicketDetails = [...ticketData];
      newTicketDetails.forEach((ticket) => {
        ticket.final_price = ticket.ticket_price;
        ticket.discount = null;
        ticket.discount_remaining = null;
        ticket.discounted_price = null;
      });
      setTicketData(newTicketDetails);
    } else if (isAccessCode) {
      getTicketDataFunction();
    }
    setCouponCode('');
  };

  return (
    <>
      <section
        id="tickets"
        ref={ref}
        className={eventData.description ? 'mt-2' : 'mt-4'}
      >
        {(ticketData?.length > 0 || hasCoupons || hasAccessCodes) && (
          <div className="">
            <div className="head">
              <h2
                className="mt-3 mb-2 "
                style={{ fontSize: '20px', color: '#000' }}
              >
                TICKETS
              </h2>
            </div>
            {(hasCoupons || hasAccessCodes) && !isLoading && (
              <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-5">
                <p className="mb-2 mb-md-0 m-0 my-2 discount-txt ">
                  {hasCoupons &&
                    !hasAccessCodes &&
                    'Apply discount code to avail discounts.'}
                  {!hasCoupons &&
                    hasAccessCodes &&
                    'Apply access code to view hidden tickets.'}
                  {hasCoupons &&
                    hasAccessCodes &&
                    'Apply discount code / access code to avail discounts & view hidden tickets'}
                </p>
                <div
                  className="ms-md-2 me-md-0 mt-2 mx-auto coupon-input-container"
                  style={{ width: '270px' }}
                >
                  <CouponInput
                    getCouponDetails={getCouponDetails}
                    removeCoupon={removeCoupon}
                    newQuery={newQuery}
                    setIsAccessCode={setIsAccessCode}
                    isAccessCode={isAccessCode}
                    colour={'#000'}
                  />
                </div>
              </div>
            )}
            {teamRegStatus === 0 ? (
              ticketData.length === 0 ? (
                <div className="d-flex tickets container p-0 p-sm-3 rounded justify-content-center">
                  No tickets available
                </div>
              ) : (
                <div className="tickets container p-0 p-sm-3 rounded justify-content-center">
                  {uncategorized.map((item) => (
                    <TicketInfoMinimalistic
                      data={item}
                      groupDiscounts={groupDiscounts}
                      eventUrl={eventUrl}
                      couponCode={couponCode}
                      newQuery={newQuery}
                      key={`${item.ticket_id}`}
                    />
                  ))}
                  {categorized.length > 0 && (
                    <div className="mx-sm-3 mx-0 my-3">
                      {categorized.map((category) => (
                        <div
                          key={category.category_id}
                          className="accordion"
                          id={`accordion-${category.category_id}`}
                        >
                          {category.tickets.length > 0 && (
                            <div className="category-box mt-4 accordion-item">
                              <h2
                                className="accordion-header"
                                id={`heading-${category.category_id}`}
                                style={{ marginBottom: '0px' }}
                              >
                                <button
                                  className={`accordion-button ${category.is_expanded ? '' : 'collapsed'
                                    }`}
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse-${category.category_id}`}
                                  aria-expanded={category.is_expanded}
                                  aria-controls={`collapse-${category.category_id}`}
                                >
                                  {category.category_name}
                                  <span className="accordion-arrow ms-2"></span>
                                </button>
                              </h2>
                              <div
                                id={`collapse-${category.category_id}`}
                                className={`accordion-collapse collapse ${category.is_expanded ? 'show' : ''
                                  }`}
                                aria-labelledby={`heading-${category.category_id}`}
                                data-bs-parent={`#accordion-${category.category_id}`}
                              >
                                <div className="tickets accordion-body p-0 p-sm-3">
                                  {category.category_description && (
                                    <div
                                      className="acc-desc"
                                      style={{ color: '#474747' }}
                                      dangerouslySetInnerHTML={{
                                        __html: category.category_description,
                                      }}
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  )}
                                  {category.tickets.map((ticket) => (
                                    <TicketInfoMinimalistic
                                      key={ticket.ticket_id}
                                      groupDiscounts={groupDiscounts}
                                      eventUrl={eventUrl}
                                      data={ticket}
                                      couponCode={couponCode}
                                      newQuery={newQuery}
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )
            ) : (
              <div className="tickets container p-0 p-sm-3 rounded justify-content-center">
                <TeamBasedComponent
                  color="#000"
                  teamRegStatus={teamRegStatus}
                  eventData={eventData}
                  onJoinTeam={(team) => {
                    sessionStorage.setItem('team_name', team.team_name);
                    sessionStorage.setItem('team_id', team.team_id);
                    sessionStorage.setItem(
                      'team_attendee_count',
                      team.team_attendee_count
                    );
                    if (isAccessCode) {
                      sessionStorage.setItem('isEventPageTeamComp', true);
                    }
                    router.push(
                      `/checkout/${eventData.event_url}?teamRegType=2&team_name=${team.team_name}&team_id=${team.team_id}${couponCode ? `&selectedCode=${couponCode}` : ''
                      }${isReferral
                        ? `&referred_by=${newQuery.referred_by}&campaign_id=${newQuery.campaign_id}&referral_mode=${newQuery.referral_mode}`
                        : ''
                      }${isAccessCode ? `&isAccessCode=1` : ''}`
                    );
                  }}
                  onCreateTeam={(val) => {
                    sessionStorage.setItem('team_name', val.team_name);
                    sessionStorage.setItem('team_lead_name', val.team_lead_name);
                    sessionStorage.setItem('team_lead_email', val.team_lead_email);
                    if (isAccessCode) {
                      sessionStorage.setItem('isEventPageTeamComp', true);
                    }
                    router.push(
                      `/checkout/${eventData.event_url}?teamRegType=1&team_name=${val.team_name}&team_lead_name=${val.team_lead_name}&team_lead_email=${val.team_lead_email}${couponCode ? `&selectedCode=${couponCode}` : ''
                      }${isReferral
                        ? `&referred_by=${newQuery.referred_by}&campaign_id=${newQuery.campaign_id}&referral_mode=${newQuery.referral_mode}`
                        : ''
                      }${isAccessCode ? `&isAccessCode=1` : ''}`
                    );
                  }}
                  onIndividual={() => {
                    if (couponCode && isAccessCode) {
                      // If multiple hidden tickets with team-based and without team-based ticket
                      // and if select individual
                      sessionStorage.setItem('isEventPageTeamComp', true);
                      router.push(
                        `/checkout/${eventData.event_url}?teamRegType=0&selectedCode=${couponCode}${isAccessCode ? `&isAccessCode=1` : ''
                        }`
                      );
                    } else {
                      router.push(
                        `/checkout/${eventData.event_url}?teamRegType=0`
                      );
                    }
                  }}
                />
              </div>
            )}
          </div>
        )}
        <style jsx>{`
          .tickets {
            max-width: 720px;
            background: #f6f6fc;
          }

          .category-box {
            border-radius: 5px !important;
            border: 2px solid #efe9ed;
            background: #fff;
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
          }
          .discount-txt {
            color: #1f2439 !important;
          }

          .accordion-button {
            font-size: 18px;
            border-radius: 5px !important;
            font-weight: 700;
          }

          .accordion-button:not(.collapsed) {
            background-color: #fff;
            color: #212529;
            box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
          }
          .accordion-button::after {
            background-image: url('/img/accordian.svg');
          }
          .accordion-button:not(.collapsed)::after {
            background-image: url('/img/accordian.svg');
            transform: rotate(-180deg);
          }

          @media (max-width: 576px) {
            .tickets {
              background: #fff;
            }
            .discount-txt {
              font-size: 14px;
              font-weight: 400;
              width:100%;
            }
            .coupon-input-container {
              width: 100% !important;
            }
            .accordion-button {
              font-weight: 500;
              font-size: 16px;
              padding: 16px 16px;
            }
            .accordion-body {
              font-weight: 400 !important;
              font-size: 14px;
              padding: 16px 16px !important;
            }
            .acc-desc {
              font-size: 14px;
              font-weight: 300 !important;
              color: #474747 !important;
            }
            .acc-desc span {
              color: #474747 !important;
            }
          }
        `}</style>
      </section>
    </>
  );
};

export default EventPricingMinimalistic;