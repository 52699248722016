import React, { useState } from "react";
import moment from "moment";
import eventContext from "../../../../contexts/EventContext";
import { useContext } from "react";
import GetCurrency from "../../../../helpers/services";
import ReadMore from "../../../../components/ReadMoreText";
import Link from "next/link";
import Loading from "../../../../components/Loading";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { discountContentByCondition, hasGroupDiscountOffer } from "../../../../helpers/common";
import TicketCard from "../../GeneralComponents/TicketCard";
import { useMediaQuery } from "react-responsive";
import { CurrencyWithAmount } from "../../../../helpers/utils";

const TicketInfoMinimalistic = ({ data, eventUrl, newQuery, couponCode, groupDiscounts }) => {
	const { eventData } = useContext(eventContext);
	const [loading, setLoading] = useState(false); //to show loading icon after clicked on ticket

	//Dont show ticket venue if it is same as event venue
	const showVenue = data.venue && eventData.venue === data.venue ? false : true;

	const discount_conditon = [1, 2];

	let ticket_start_timestamp = data.start_timestamp;
	let ticket_end_timestamp = data.end_timestamp;
	let event_end_timestamp = `${eventData.end_date} ${eventData.end_time}`;

	const date = {
		start_month: moment.utc(ticket_start_timestamp).local().format("MMM Do"),
		end_month: moment.utc(ticket_end_timestamp).local().format("MMM Do"),
		start_day: moment.utc(ticket_start_timestamp).local().format("ddd"),
		end_day: moment.utc(ticket_end_timestamp).local().format("ddd"),
		start_year: moment.utc(ticket_start_timestamp).local().format("YYYY"),
		end_year: moment.utc(ticket_end_timestamp).local().format("YYYY"),
		start_time: moment.utc(ticket_start_timestamp).local().format("HH:mm"),
		end_time: moment.utc(ticket_end_timestamp).local().format("HH:mm")
	};

	const handleMediaQueryChange = matches => {
		smallerScreen = matches;
		// matches will be true or false based on the value for the media query
	};
	let smallerScreen = useMediaQuery({ maxWidth: 535 }, undefined, handleMediaQueryChange);

	const buttonType = ticket => {
		var todaysTime = moment().valueOf();
		// convert utc to local
		let ticketStartTime = moment.utc(ticket_start_timestamp).local().valueOf();
		let ticketEndTime = moment.utc(ticket_end_timestamp).local().valueOf();
		let eventEndTime = moment.utc(event_end_timestamp).local().valueOf();

		switch (true) {
			case todaysTime > ticketEndTime || todaysTime > eventEndTime:
				return (
					<button className="minimalistic-btn w-100" disabled>
						Expired
					</button>
				);
			case todaysTime > ticketEndTime:
				return (
					<button className="minimalistic-btn w-100" disabled>
						Sold Out
					</button>
				);
			case (ticket.sold_out && !ticket.waitlist_enabled) ||
				(ticket.remaining_count <= 0 && !ticket.waitlist_enabled):
				return (
					<button className="minimalistic-btn w-100" disabled>
						Sold Out
					</button>
				);
			case ticketStartTime > todaysTime:
				return (
					<button className="minimalistic-btn w-100" disabled>
						Coming Soon
					</button>
				);
			case ticket.approval_enabled:
				return (
					<Link
						href={{
							pathname: `/checkout/${eventUrl}`,
							query: {
								ticketId: `${ticket.ticket_id}`,
								...(ticket.teamBasedEvent && { teamRegType: 0 }),
								...(couponCode && { selectedCode: couponCode }),
								...newQuery
							}
						}}
					>
						<div style={{ textDecoration: "none" }}>
							<button
								onClick={() => setLoading(true)}
								className="minimalistic-btn w-100"
							>
								{loading ? (
									<Loading size="16px" color="#fff" />
								) : smallerScreen ? (
									<>{ticket.ticket_type === 3 ? "Donate" : "Register"}</>
								) : (
									"Register your interest"
								)}
							</button>
						</div>
					</Link>
				);
			case !ticket.sold_out && ticket.remaining_count <= 0 && ticket.waitlist_enabled:
				return (
					<Link
						href={{
							pathname: `/checkout/${eventUrl}`,
							query: {
								ticketId: `${ticket.ticket_id}`,
								...(ticket.teamBasedEvent && { teamRegType: 0 }),
								...(couponCode && { selectedCode: couponCode }),
								...newQuery
							}
						}}
					>
						<div style={{ textDecoration: "none" }}>
							<button
								onClick={() => setLoading(true)}
								className="minimalistic-btn w-100"
							>
								{loading ? <Loading size="16px" color="#fff" /> : "Join Waitlist"}
							</button>
						</div>
					</Link>
				);
			case ticket.sold_out && ticket.waitlist_enabled:
				return (
					<Link
						href={{
							pathname: `/checkout/${eventUrl}`,
							query: {
								ticketId: `${ticket.ticket_id}`,
								...(ticket.teamBasedEvent && { teamRegType: 0 }),
								...(couponCode && { selectedCode: couponCode }),
								...newQuery
							}
						}}
					>
						<div style={{ textDecoration: "none" }}>
							<button
								onClick={() => setLoading(true)}
								className="minimalistic-btn w-100"
							>
								{loading ? <Loading size="16px" color="#fff" /> : "Join Waitlist"}
							</button>
						</div>
					</Link>
				);
			case ticketStartTime < ticketEndTime && !ticket.sold_out:
				return (
					<Link
						href={{
							pathname: `/checkout/${eventUrl}`,
							query: {
								ticketId: `${ticket.ticket_id}`,
								...(ticket.teamBasedEvent && { teamRegType: 0 }),
								...(couponCode && { selectedCode: couponCode }),
								...newQuery
							}
						}}
					>
						<div style={{ textDecoration: "none" }}>
							<button
								onClick={() => setLoading(true)}
								className="minimalistic-btn mini w-100"
							>
								{loading ? (
									<Loading size="16px" color="#fff" />
								) : ticket.ticket_type === 3 ? (
									"Donate"
								) : (
									"Register"
								)}
							</button>
						</div>
					</Link>
				);
			default:
				break;
		}
	};

	var todaysTime = moment().valueOf();
	// convert utc to local
	let ticketStartTime = moment.utc(ticket_start_timestamp).local().valueOf();

	const hasGroupDiscount =
		!data.is_free &&
			hasGroupDiscountOffer(groupDiscounts, data.ticket_id)
			? true
			: false;

	const findMaxDiscount = (ticket, groupDiscount) => {
		const { ticket_id, totalSelected, ticket_price } = ticket;

		let maxDiscount = 0;
		let maxDiscountType = 0; // 1 for flat, 2 for percentage
		let discountVal = 0;

		groupDiscount.forEach(discount => {
			const {
				attached_ticket_ids,
				minimum_tickets,
				maximum_tickets,
				coupon_type,
				discount: discountValue
			} = discount;

			// Check if the ticket ID is attached to the discount and if the totalSelected is within the min and max ticket range
			if (
				attached_ticket_ids.includes(ticket_id) && minimum_tickets === 0
			) {
				let calculatedDiscount = 0;

				// Calculate the discount based on coupon type
				if (coupon_type === 2) {
					calculatedDiscount = ticket_price - ((discountValue / 100) * ticket_price);
				} else if (coupon_type === 1) {
					calculatedDiscount = discountValue;
				}

				discountVal = discountValue

				// Check if the calculated discount is greater than the current maxDiscount
				if (calculatedDiscount > maxDiscount) {
					maxDiscount = calculatedDiscount;
					maxDiscountType = coupon_type;
				}
			}
		});

		// Calculate the total discount and final price
		let totalDiscount = 0;

		if (maxDiscountType === 2) {
			totalDiscount = (ticket_price - maxDiscount);
		} else if (maxDiscountType === 1) {
			totalDiscount = maxDiscount;
		}

		return { totalDiscount, maxDiscountType, discountVal };
	};

	const showPrice = ticket => {
		const ticketPrice = ticket.ticket_price;
		// checking if the automatic_discount_rule have minimun ticket purchase is 0
		let hasMinimumTicketsZero =
			ticket.automatic_discount_rules &&
			ticket.automatic_discount_rules.some(rule => rule.minimum_tickets === 0);

		let index;
		if (hasMinimumTicketsZero) {
			index = ticket.automatic_discount_rules.findIndex(rule => rule.minimum_tickets === 0);
		}

		let { totalDiscount = 0, maxDiscountType = 0, discountVal = 0 } = {};

		if (hasGroupDiscount) {
			({ totalDiscount, maxDiscountType, discountVal } = findMaxDiscount(ticket, groupDiscounts));
		}

		if (ticket.discount) {
			return (
				<>
					<span className="ms-1 d-inline-block">
						{ticket.final_price === 0 ? (
							"FREE"
						) : (
							<CurrencyWithAmount
								currencyName={ticket.currency_name}
								number={ticket.final_price}
							/>
						)}
					</span>
					<span className="ms-1 currency_number_strikeThrough strike">
						<CurrencyWithAmount
							fontSize={"14px"}
							currencyName={ticket.currency_name}
							number={ticketPrice}
						/>
						<style jsx>{`
							.strike {
								text-decoration: line-through;
								// text-decoration-thickness: 2px;
								opacity: 0.6;
							}
							.currency_number_strikeThrough {
								font-size: 14px !important;
								line-height: 38px;
								vertical-align: text-top;
							}
						`}</style>
					</span>
				</>
			);
		} else if (
			hasMinimumTicketsZero &&
			ticket.automatic_discount_rules[index].discount_type == 1
		) {
			return (
				<>
					<span className="ms-1 d-inline-block">
						<CurrencyWithAmount
							currencyName={ticket.currency_name}
							number={Math.round(
								ticketPrice - ticket.automatic_discount_rules[index].discount_value
							)}
						/>
					</span>
					<span className="ms-1 currency_number_strikeThrough strike">
						<CurrencyWithAmount
							fontSize={"14px"}
							currencyName={ticket.currency_name}
							number={ticketPrice}
						/>
						<style jsx>{`
							.strike {
								text-decoration: line-through;
								// text-decoration-thickness: 2px;
								opacity: 0.6;
							}
							.currency_number_strikeThrough {
								font-size: 14px !important;
								line-height: 38px;
								vertical-align: text-top;
							}
						`}</style>
					</span>
				</>
			);
		} else if (
			hasMinimumTicketsZero &&
			ticket.automatic_discount_rules[index].discount_type == 2
		) {
			return (
				<>
					<span className="ms-1 d-inline-block">
						<CurrencyWithAmount
							currencyName={ticket.currency_name}
							number={Math.round(
								ticketPrice -
								ticketPrice *
								(ticket.automatic_discount_rules[index].discount_value /
									100)
							)}
						/>
					</span>
					<span className="ms-1 currency_number_strikeThrough strike">
						<CurrencyWithAmount
							fontSize={"14px"}
							currencyName={ticket.currency_name}
							number={ticketPrice}
						/>
						<style jsx>{`
							.strike {
								text-decoration: line-through;
								// text-decoration-thickness: 2px;
								opacity: 0.6;
							}
							.currency_number_strikeThrough {
								font-size: 14px !important;
								line-height: 38px;
								vertical-align: text-top;
							}
						`}</style>
					</span>
				</>
			);
		} else if (hasGroupDiscount && totalDiscount) {
			return (
				<>
					<span className="ms-1 d-inline-block">
						<CurrencyWithAmount
							currencyName={ticket.currency_name}
							number={
								ticketPrice - totalDiscount
							}
						/>
					</span>
					<span className="ms-1 currency_number_strikeThrough strike">
						<CurrencyWithAmount
							fontSize={"14px"}
							currencyName={ticket.currency_name}
							number={ticketPrice}
						/>
					</span>
					<div className="mb-2 mb-lg-0 save_group_discount_tag d-flex align-items-center justify-content-center">
						<div className="save_group_discount_text">
							{
								maxDiscountType === 2 ?
									<span>Save{" "}
										<span>
											<CurrencyWithAmount
												fontSize={"12px"}
												currencyName={ticket.currency_name}
												number={totalDiscount}
											/>{` (${discountVal}%)`}
										</span>
									</span>
									:
									<span>Save{" "}
										<span>
											<CurrencyWithAmount
												fontSize={"12px"}
												currencyName={ticket.currency_name}
												number={totalDiscount}
											/>
										</span>
									</span>
							}
						</div>
					</div>
					<style jsx>{`
						.strike {
							text-decoration: line-through !important;
							// text-decoration-thickness: 2px !important;
							opacity: 0.6 !important;
						}

						.save_group_discount_tag {
							width: fit-content;
							min-height: 22px;
							padding: 0px 9px 0px 9px;
							border-radius: 15px;
							background: #FDB14B;
						}
							
						.save_group_discount_text {
							font-family: Prompt;
							font-size: 12px;
							font-weight: 500;
							line-height: 21px;
							letter-spacing: 1px;
							color: #FFFFFF;
						}
						.currency_number_strikeThrough {
							font-size: 14px !important;
							line-height: 38px;
							vertical-align: text-top;
						}
					`}</style>
				</>
			);
		} else {
			return (
				<span className="ms-1 d-inline-block">
					<CurrencyWithAmount currencyName={ticket.currency_name} number={ticketPrice} />
				</span>
			);
		}
	};

	const getTZ = (date, tz) => {
		return moment.tz(`${date}`, tz).format("z");
	};

	const getTicketStatus = ticket => {
		var todaysTime = moment().valueOf();
		// convert utc to local
		let ticketStartTime = moment.utc(ticket_start_timestamp).local().valueOf();
		let ticketEndTime = moment.utc(ticket_end_timestamp).local().valueOf();
		let eventEndTime = moment.utc(event_end_timestamp).local().valueOf();

		switch (true) {
			case todaysTime > eventEndTime:
				return "Expired";
			case todaysTime > ticketEndTime:
				return "Sold Out";
			case (ticket.sold_out && !ticket.waitlist_enabled) ||
				(ticket.remaining_count <= 0 && !ticket.waitlist_enabled):
				return "Sold Out";
			case ticketStartTime > todaysTime:
				return "Coming Soon";
			case ticket.approval_enabled:
				return "Approval";
			case !ticket.sold_out && ticket.remaining_count <= 0 && ticket.waitlist_enabled:
				return "Waitlist";
			case ticket.sold_out && ticket.waitlist_enabled:
				return "Waitlist";
			case ticketStartTime < ticketEndTime && !ticket.sold_out:
				return "Register";
			default:
				break;
		}
	};

	const getRemainingText = count => {
		switch (true) {
			case count === 3:
				return "Only 3 tickets left!";

			case count === 2:
				return "Only 2 tickets left!";

			case count === 1:
				return "Only 1 ticket left!";

			case count <= 10:
				return "Only few tickets left!";

			case count <= 0:
				return null;

			default:
				return null;
		}
	};



	return (
		<>
			<TicketCard
				data={data}
				hasGroupDiscount={hasGroupDiscount}
				fontFamily={"Manrope"}
				nameFontWeight={"700"}
				discountContent={
					hasGroupDiscount &&
					groupDiscounts
						.filter(item => discount_conditon.includes(item.coupon_type))
						.map((item, index) => (
							<div key={index}>{discountContentByCondition(item, data)}</div>
						))
				}
				ticketStartTime={ticketStartTime}
				todaysTime={todaysTime}
				showVenue={showVenue}
				ticketStatus={
					getTicketStatus(data) === "Register" && getRemainingText(data.remaining_count)
				}
				ticketPrice={showPrice(data)}
				buttonType={buttonType(data)}
				getTZ={getTZ}
				eventData={eventData}
				moreBtnColor={"#000"}
				groupDiscounts={groupDiscounts}
			/>
			<style jsx>{`
				.strike {
					text-decoration: line-through;
					// text-decoration-thickness: 2px;
					opacity: 0.6;
				}
				.off-img {
					margin: -17px -21px 0;
				}
				.ticket-name {
					font-size: 1.375rem;
					font-weight: 800;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					word-break: break-word;
				}

				.desc-section {
					// min-height: 65px;
				}

				.remaining-count {
					height: 22px;
					letter-spacing: 0.5px;
					font-weight: 500;
					font-size: 0.875rem;
					line-height: 22px;
					color: #dc3545;
				}

				.name-section {
					// min-height: 72px;
					// max-height: 78px;
				}

				.ticket-desc {
					min-height: 45px;
				}

				.availibility {
					background: #ffffff;
					border-radius: 6px;
				}
				.availibility p {
					font-size: 0.875rem;
					font-weight: 500;
					letter-spacing: 0em;
				}

				.ticket-price {
					font-size: 2.75rem;
					font-weight: 800;
					text-align: center;
				}
				.ticket-card {
					background: #fff;
					border: 0.8px solid #cacaca;
					border-radius: 6px;
					// max-width: 340px !important;
					// padding: 30px 35px 30px 35px;
				}
				.donation-info {
					font-weight: 600;
					font-size: 20px;
				}

				@media (min-width: 992px) and (max-width: 1368px) {
					.ticket-price {
						font-size: 2.25rem;
					}
					.ticket-name {
						font-size: 1.25rem;
					}
					.donation-info {
						font-size: 14px;
					}
				}
				@media screen and (max-width: 576px) {
					.ticket-card {
						padding: 16px 16px !important;
					}
				}
			`}</style>
		</>
	);
};

export default TicketInfoMinimalistic;
